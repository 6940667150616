import checklistsImg from '@/dataroom/ui/components/Landing/researchRoom/assets/checklists.webp';
import trailsImg from '@/dataroom/ui/components/Landing/researchRoom/assets/trails.webp';
import restrictionsImg from '@/dataroom/ui/components/Landing/researchRoom/assets/restrictions.webp';
import alertsImg from '@/dataroom/ui/components/Landing/researchRoom/assets/alerts.webp';
import disclaimersImg from '@/dataroom/ui/components/Landing/researchRoom/assets/disclaimers.webp';
import IconWarning from '@dealroadshow/uikit/core/components/Icon/IconWarning';
import IconEmailAlerts from '@dealroadshow/uikit/core/components/Icon/IconEmailAlerts';
import IconEarth from '@dealroadshow/uikit/core/components/Icon/IconEarth';
import IconList from '@dealroadshow/uikit/core/components/Icon/IconList';
import IconCheckCircle from '@dealroadshow/uikit/core/components/Icon/IconCheckCircle';

export const detailsTabsList = [
  {
    key: 'checklists',
    icon: IconCheckCircle,
    title: 'Automated checklists & stages',
    description: (
      <p>
        Pre-schedule the day and time research goes "Live" once mandatory setup steps on real-time
        checklists have been completed.
      </p>
    ),
    img: {
      src: checklistsImg,
      width: 1420,
      height: 896,
      key: 1,
    },
  },
  {
    key: 'trails',
    icon: IconList,
    title: 'Clean, exportable audit trails',
    description:
      (
        <p>
          View and export updated, detailed audit trails of investor and admin activity from the "Settings" menu in
          clean, client-ready formats.
        </p>
      ),
    img: {
      src: trailsImg,
      width: 1420,
      height: 896,
      key: 2,
    },
  },
  {
    key: 'restrictions',
    icon: IconEarth,
    title: 'Country specific restrictions',
    description: (
      <p>
        ResearchRoom sends automated warnings to investors with IP addresses in restricted countries and
        blocks access to research if they’ve self-certified in a restricted country of business.
      </p>
    ),
    img: {
      src: restrictionsImg,
      width: 1420,
      height: 896,
      key: 3,
    },
  },
  {
    key: 'alerts',
    icon: IconEmailAlerts,
    title: 'Go live invite lists & email alerts',
    description: (
      <p>
        Build an invite list and custom email invitation for investors. Send test emails to see a sample
        before a room goes live.
      </p>
    ),
    img: {
      src: alertsImg,
      width: 1420,
      height: 896,
      key: 4,
    },
  },
  {
    key: 'disclaimers',
    icon: IconWarning,
    title: 'Custom initial and post-closing disclaimers',
    description: (
      <p>
        Create unique disclaimers for investors to enter your ResearchRoom in "Initial Posting" and
        "Post-Closing" stages.
      </p>
    ),
    img: {
      src: disclaimersImg,
      width: 1420,
      height: 896,
      key: 5,
    },
  },
];
