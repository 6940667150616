import SectionWithImages from '@/Framework/UI/Pages/Landing/Sections/Tabs/SectionWithImages';
import { ITabList } from '@/Framework/UI/Pages/Landing/Sections/Tabs/interfaces';
import administrationImg from '@/dataroom/ui/components/Landing/researchRoom/assets/administration.webp';
import analyticsImg from '@/dataroom/ui/components/Landing/researchRoom/assets/analytics.webp';
import fileAccessImg from '@/dataroom/ui/components/Landing/researchRoom/assets/fileAccess.webp';
import securityAndComplianceImg from '@/dataroom/ui/components/Landing/researchRoom/assets/securityAndCompliance.webp';
import supportOneImg from '@/dataroom/ui/components/Landing/researchRoom/assets/supportOne.webp';
import supportTwoImg from '@/dataroom/ui/components/Landing/researchRoom/assets/supportTwo.webp';

import styles from './features.scss';
import IconFileLock from '@dealroadshow/uikit/core/components/Icon/IconFileLock';
import IconSecurity from '@dealroadshow/uikit/core/components/Icon/IconSecurity';
import IconAnalytics from '@dealroadshow/uikit/core/components/Icon/IconAnalytics';
import IconOperator from '@dealroadshow/uikit/core/components/Icon/IconOperator';
import IconCheck from '@dealroadshow/uikit/core/components/Icon/IconCheck';
import IconFolder from '@dealroadshow/uikit/core/components/Icon/IconFolder';

const Feature = ({ text }: { text: string }) => (
  <div className={ styles.feature }>
    <div className={ styles.featureIcon }>
      <IconCheck />
    </div>
    <div className={ styles.featureTitle }>{ text }</div>
  </div>
);

export const featuresTabsList: ITabList[] = [
  {
    key: 'administration',
    icon: IconFolder,
    label: 'Administration',
    content: (
      <SectionWithImages
        images={ [
          {
            src: administrationImg,
            width: 710,
            height: 447,
            key: 1,
          },
        ] }
        featuresClassName={ styles.administrationFeatures }
        caption='Admins can manage investor access requests in real time from the "Manage Requests" page of your ResearchRoom’s settings.'
      >
        { [
          'Instant ResearchRoom provisioning',
          'Customizable country-specific restrictions',
          'Customizable disclaimer for "Closing" stage',
          'Stage users & permission groups before "Live"',
          'Customizable user email invitations',
          'Automated research stage scheduling',
          'Allow investors to request access to new ResearchRooms in "Live" stage (if enabled)',
          'Whitelisting research on "Explore" tab \n by email & domain',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'fileAccess',
    icon: IconFileLock,
    label: 'File Access',
    content: (
      <SectionWithImages
        images={ [
          {
            src: fileAccessImg,
            width: 710,
            height: 447,
            key: 2,
          },
        ] }
        featuresClassName={ styles.fileAccessFeatures }
        caption='If enabled, investors can view and request access to Live ResearchRooms from the "Explore" tab of the My Research page.'
      >
        { [
          'Discover new research reports via \n "Explore" tab (if enabled)',
          'Stage users & permission groups before "Live"',
          'Restrict or enable print & download',
          'Customizable disclaimer for "Closing" stage',
          'Search within research room by keyword',
          'Dynamic watermarking',
          'Front end file/folder permission management',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'securityAndCompliance',
    icon: IconSecurity,
    label: 'Security & Compliance',
    content: (
      <SectionWithImages
        images={ [
          {
            src: securityAndComplianceImg,
            width: 710,
            height: 447,
            key: 3,
          },
        ] }
        featuresClassName={ styles.securityFeatures }
        caption="All investors are required to explicitly consent to electronic delivery and certify their country of business before accessing ResearchRoom."
      >
        { [
          'Mandatory consent to electronic delivery',
          'Customizable country-specific restrictions',
          'Scheduled or manual go "Live"',
          'Compliance checklist for research \n stage management ',
          'GDPR compliant; EU-US Data Privacy Framework',
          'EU & US file storage regions',
          'User-specific exportable audit trails',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'analytics',
    icon: IconAnalytics,
    label: 'Analytics',
    content: (
      <SectionWithImages
        images={ [
          {
            src: analyticsImg,
            width: 710,
            height: 429,
            key: 4,
          },
        ] }
        featuresClassName={ styles.analyticsFeatures }
        caption="ResearchRoom provides admins with real-time visibility into investor engagement throughout all stages of your process."
      >
        { [
          'Exportable client-ready reporting',
          'Real-time email alerts & weekly summaries',
          'Track activity by Account',
          'Track activity by Contact',
          'Track activity by File',
          'Cross-research visibility by Account & Contact',
          'Immutable, detailed audit trails',
          'Accessible in perpetuity, for free',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'support',
    icon: IconOperator,
    label: 'Support',
    content: (
      <SectionWithImages
        images={ [
          {
            src: supportOneImg,
            width: 268,
            height: 447,
            key: 5,
          },
          {
            src: supportTwoImg,
            width: 268,
            height: 447,
            key: 6,
          },
        ] }
        featuresClassName={ styles.supportFeatures }
        caption="Ask questions and submit requests to our fanatical 24x7 support directly within your ResearchRoom with live chat."
      >
        { [
          'Unlimited 24x7x365 support for all stakeholders',
          'Phone, email and live chat compatability',
          '99.9% uptime guarantee',
          'Sub-10 minute response time to all emails',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
];
