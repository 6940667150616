import React from 'react';
import cn from 'classnames';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import FeaturesComponent from '@/Framework/UI/Pages/Landing/Sections/Features';
import Section from '@/Framework/UI/Pages/Landing/Sections/Section';
import SectionContainer from '@/Framework/UI/Pages/Landing/Sections/SectionContainer';
import SectionHeader from '@/Framework/UI/Pages/Landing/Sections/SectionHeader';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { featuresTabsList } from '../../Features/tabs';

import baseStyles from '@/dataroom/ui/components/Landing/researchRoom/tenantResearchRoomStyles.scss';
import styles from './features.scss';

const Features = () => (
  <Section padding="top">
    <SectionContainer>
      <SectionHeader
        title="IPO research distribution just got better."
        description="ResearchRoom provides everything you need for seamless, simplified pre-deal research distribution."
        maxWidth={ 860 }
      />
    </SectionContainer>
    <SectionContainer size="large" narrow>
      <FeaturesComponent
        autoplay
        actionButton={ (
          <NextNavLink
            to="/features"
            dataTest="exploreOurFeatures"
          >
            <Button
              variant={ ButtonVariantType.action }
              className={ cn(baseStyles.actionButton, styles.exploreButton) }
              title="Explore More Features"
              dataTest="exploreOurFeaturesButton"
            />
          </NextNavLink>
        ) }
        tabsList={ featuresTabsList }
      />
    </SectionContainer>
  </Section>
);

export default Features;
